import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PostLoop from "../components/PostLoop"
import { Helmet } from "react-helmet";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import IconsMap from "../util/IconsMap"
import { useTranslation } from "react-i18next"

const AuthorPage = ({ data, pageContext }) => {
  const author = data.authorsJson
  const { t } = useTranslation()
  const posts = data.allMarkdownRemark.nodes
  const totalPosts = data.allMarkdownRemark.totalCount
  const UserIcon = IconsMap["user"]

  // Extract language from pageContext or other source
  const language = pageContext.language || 'en'
  const authorslug = author?.fields?.slug || ''

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: language }}>
        {/* Add hrefLang attribute in the head */}
        <link rel="alternate" hrefLang="en" href={`https://www.quranplayermp3.com/author${authorslug}`} />
        <link rel="alternate" hrefLang="ar" href={`https://www.quranplayermp3.com/ar/author${authorslug}`} />
        <link rel="alternate" hrefLang="fr" href={`https://www.quranplayermp3.com/fr/author${authorslug}`} />
        <link rel="alternate" hrefLang="de" href={`https://www.quranplayermp3.com/de/author${authorslug}`} />
        <link rel="alternate" hrefLang="tr" href={`https://www.quranplayermp3.com/tr/author${authorslug}`} />
        <link rel="alternate" hrefLang="es" href={`https://www.quranplayermp3.com/es/author${authorslug}`} />
        <link rel="alternate" hrefLang="zh" href={`https://www.quranplayermp3.com/zh/author${authorslug}`} />
        <link rel="alternate" hrefLang="ur" href={`https://www.quranplayermp3.com/ur/author${authorslug}`} />
        <link rel="alternate" hrefLang="ru" href={`https://www.quranplayermp3.com/ru/author${authorslug}`} />
        <link rel="alternate" hrefLang="pt" href={`https://www.quranplayermp3.com/pt/author${authorslug}`} />
        <link rel="alternate" hrefLang="it" href={`https://www.quranplayermp3.com/it/author${authorslug}`} />
        <link rel="alternate" hrefLang="id" href={`https://www.quranplayermp3.com/id/author${authorslug}`} />
        <link rel="alternate" hrefLang="bn" href={`https://www.quranplayermp3.com/bn/author${authorslug}`} />
        <link rel="alternate" hrefLang="nl" href={`https://www.quranplayermp3.com/nl/author${authorslug}`} />
        <link rel="alternate" hrefLang="hi" href={`https://www.quranplayermp3.com/hi/author${authorslug}`} />
        <link rel="alternate" hrefLang="fa" href={`https://www.quranplayermp3.com/fa/author${authorslug}`} />
        <link rel="alternate" hrefLang="ug" href={`https://www.quranplayermp3.com/ug/author${authorslug}`} />
        <link rel="alternate" hrefLang="so" href={`https://www.quranplayermp3.com/so/author${authorslug}`} />
        <link rel="alternate" hrefLang="sw" href={`https://www.quranplayermp3.com/sw/author${authorslug}`} />
        <link rel="alternate" hrefLang="no" href={`https://www.quranplayermp3.com/no/author${authorslug}`} />
        <link rel="alternate" hrefLang="da" href={`https://www.quranplayermp3.com/da/author${authorslug}`} />
        <link rel="alternate" hrefLang="se" href={`https://www.quranplayermp3.com/se/author${authorslug}`} />
        <link rel="alternate" hrefLang="ha" href={`https://www.quranplayermp3.com/ha/author${authorslug}`} />
        <link rel="alternate" hrefLang="bs" href={`https://www.quranplayermp3.com/bs/author${authorslug}`} />
        <link rel="alternate" hrefLang="th" href={`https://www.quranplayermp3.com/th/author${authorslug}`} />
        <link rel="alternate" hrefLang="tg" href={`https://www.quranplayermp3.com/tg/author${authorslug}`} />
        <link rel="alternate" hrefLang="tl" href={`https://www.quranplayermp3.com/tl/author${authorslug}`} />
        <link rel="alternate" hrefLang="x-default" href={`https://www.quranplayermp3.com/author${authorslug}`} />
      </Helmet>
      <Seo
        title={t(author.name)}
        isAuthorPage={true}
        author={{
          name: t(author.name),
          fields: { slug: authorslug }, // Use the extracted slug
          profilePicture: author.profilePicture,
        }}
        description={t(author.description)}
        image={author.profilePicture ? getImage(author.profilePicture).src : null}
      />
      <div className="main">
        <div className="container">
          <div className="archive-cover">
            <div className={`archive-cover-inner cover-author flex ${author.coverImage ? "has-image" : ""}`}>
              {author.coverImage && (
                <GatsbyImage
                  className="cover-image"
                  image={getImage(author.coverImage)}
                  alt={`${author.name} cover image`}
                />
              )}
              <div className="cover-content-wrapper flex">
                <div className="avatar-wrap">
                  {author.profilePicture !== null ? (
                    <GatsbyImage
                      image={getImage(author.profilePicture)}
                      alt={author.name}
                    />
                  ) : (
                    <div className="avatar no-image">
                      <UserIcon />
                    </div>
                  )}
                </div>
                <div className="author-info">
                  <h1 className="name h4">{t(author.name)}</h1>
                  <div className="author-meta">
                    {author.location && (
                      <span className="author-location">{t(author.location)}</span>
                    )}
                    <span className="post-count">
                      {totalPosts > 1 ? `${totalPosts} ${t('posts')}` : `${totalPosts} ${t('post')}`}
                    </span>
                  </div>

                  {author.description && (
                    <div className="bio">{t(author.description)}</div>
                  )}
                  <div className="author-social">
                    {author.socialLinks &&
                      author.socialLinks.map((item, index) => (
                        <a
                          key={index}
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {(() => {
                            const Icon =
                              IconsMap[item.platform.toLowerCase()] ||
                              IconsMap["default"]
                            return <Icon />
                          })()}
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PostLoop posts={posts} />
      </div>
    </Layout>
  )
}

export default AuthorPage

export const query = graphql`
  query AuthorPageQuery($slug: String!, $language: String!) {
    authorsJson(fields: { slug: { eq: $slug } }) {
      ...AuthorQueryFragment
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          published: { ne: false }
          author: { fields: { slug: { eq: $slug } } }
        }
      }
    ) {
      totalCount
      nodes {
        ...PostQueryFragment
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
